import axios from "axios";
//const baseURL = 'http://localhost:8080/users';
//const baseURL = 'https://uatcompliance.arkafincap.com/users';
  const baseURL = 'https://compliance.arkafincap.com/users';





export const verifyEmail = async (email) => {
    // const config = { headers: { authorization: `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0aW9uIiwidXNlclR5cGUiOiJ3aG9sZXNhbGUiLCJpYXQiOjE2NzU2NjYyMzR9.GrADzNxuhqCypJdM1xw7Db2k4AhBZ_PB_zDCmeAozkw'}` } }; 
    try {
        //const result = await axios.get(`https://uatcompliance.arkafincap.com/users/register/verify-email/${email}`)
        const result = await axios.get(`https://compliance.arkafincap.com/users/register/verify-email/${email}`)
        if (result) {
            console.log('usertoken')
            console.log(result)
            return result;
        }
    } catch (error) {
        return error;
    }

}



export const insertEmailTemp = async (data) => {
    axios.post(baseURL + `/insert-emailTemp/ByCreatedById`, data)
        .then(response => {
            if (response.status === 200) {
                console.log(response);
            }
            else {
                console.log(response);
            }
        })
        .catch(error => {
            console.log(error)
        });
}


export const getAllEmilTemp = async (callback) => {
    try {
        const result = await axios.get(baseURL + `/getAll-emailTemp`)
        if (result) {
            return callback(null, result);
        }
    } catch (error) {
        return callback(error, null);
    }

}


export const getComplianceByCreatedBy = async (id,userLike,departmentLike, callback) => {
    axios.get(baseURL + `/getAll-complianceByCreatedby/${id}/${userLike}/${departmentLike}`)
        .then(response => {
            if (response.status === 200) {
                return callback(null, response);
            }
            else {
                return callback(null, response);
            }
        })
        .catch(error => {
            return callback(error, null)
        });
}


export const getComplianceByid = async (id, callback) => {
    axios.get(baseURL + `/get-complianceByComplianceId/${id}`)
        .then(response => {
            if (response.status === 200) {
                return callback(null, response);
            }
            else {
                return callback(null, response);
            }
        })
        .catch(error => {
            return callback(error, null)
        });
}


export const insertComplianceDetails = async (data, callback) => {
    console.log('api-call')
    const formData = new FormData();
    // const fileType = data.uri.split('.').pop().split(/\#|\?/)
    //'doc_' + random + '_' + currentTimeStamp + '.' + 'word'
    let currentTimeStamp = Date.now();
    var random = Math.floor(1000 + Math.random() * 9000)
    formData.append("doc_file_name", data.uri ? data.uri.name : 'Not uploaded');
    formData.append("compliance_name", data.compliance_name);
    formData.append("user_email", data.user_email);
    formData.append("start_date", data.start_date);
    formData.append("end_date", data.end_date);
    formData.append("email_temp", data.email_temp);
    formData.append("compliance_type", data.compliance_type);
    formData.append("is_comment", data.is_comment);
    formData.append("is_notification_before_7_days", data.is_notification_before_7_days);
    formData.append("is_notification_before_3_days", data.is_notification_before_3_days);
    formData.append("department", data.department);
    formData.append("created_by", data.created_by);
    // formData.append("created_at", 'today');
    formData.append("file", data.uri ? data.uri : null)


    // http://localhost:8080/users/insert-compliance-details/ByCreatedById
    await axios({
        method: 'post',
        url: baseURL + `/insert-compliance-details/ByCreatedById`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
        // 'Content-Type':'application/x-www-form-urlencoded'   'multipart/form-data'
    })
        .then(function (response) {
            // console.log(response);
            return callback(null, response);
        })
        .catch(function (response) {
            return callback(null, response);
            // console.log(response);
        })
}




export const updateComplianceInfo = async (data, callback) => {
    console.log('api-call')
    const formData = new FormData();
    // const fileType = data.uri.split('.').pop().split(/\#|\?/)
    let currentTimeStamp = Date.now();
    var random = Math.floor(1000 + Math.random() * 9000)
    formData.append("doc_file_name", data.doc_file_name);
    formData.append("compliance_name", data.compliance_name);
    formData.append("user_email", data.user_email);
    formData.append("start_date", data.start_date);
    formData.append("end_date", data.end_date);
    formData.append("email_temp", data.email_temp);
    formData.append("compliance_type", data.compliance_type);
    formData.append("is_comment", data.is_comment);
    formData.append("is_notification_before_7_days", data.is_notification_before_7_days);
    formData.append("is_notification_before_3_days", data.is_notification_before_3_days);
    formData.append("department", data.department);
    formData.append("id", data.id);
    formData.append('file', data.uri)


    await axios({
        method: 'put',
        url: baseURL + `/update-complianceInfo`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' } // 'Content-Type':'application/x-www-form-urlencoded'   'multipart/form-data'
    })
        .then(function (response) {
            return callback(null, response);
        })
        .catch(function (response) {
            return callback(null, response);
        })
}


export const getAllUserInfo = async (callback) => {
    try {
        const result = await axios.get(baseURL + `/getAll-userList`)
        if (result) {
            return callback(null, result);
        }
    } catch (error) {
        return callback(error, null);
    }

}




export const getComplianceByUserEmail = async (userEmail, callback) => {
    axios.get(baseURL + `/getAll-complianceByUserEmail/${userEmail}`)
        .then(response => {
            if (response.status === 200) {
                return callback(null, response);
            }
            else {
                return callback(null, response);
            }
        })
        .catch(error => {
            return callback(error, null)
        });
}


export const getTransactionDataByComId = async (complianceId, callback) => {
    axios.get(baseURL + `/getAll-transactionByComplianceId/${complianceId}`)
        .then(response => {
            if (response.status === 200) {
                return callback(null, response);
            }
            else {
                return callback(null, response);
            }
        })
        .catch(error => {
            return callback(error, null)
        });
}





export const updateTransactionInfo = async (data, callback) => {
    console.log('api-call')
    const formData = new FormData();
    // const fileType = data.uri.split('.').pop().split(/\#|\?/)
    let currentTimeStamp = Date.now();
    var random = Math.floor(1000 + Math.random() * 9000)
    formData.append("comId", data.comId);
    formData.append("uploadedDocFile", data.uploadedDocFile ? data.uploadedDocFile.name : 'Not uploaded');
    formData.append("comment", data.comment);
    formData.append("status", data.status);
    // formData.append("created_at", 'today');
    formData.append("file", data.uploadedDocFile ? data.uploadedDocFile : null)

    await axios({
        method: 'put',
        url: baseURL + `/update-transactionInfo`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(function (response) {
            return callback(null, response);
        })
        .catch(function (response) {
            return callback(response, null);
        })
}



export const getAllDepartment = async (callback) => {
    try {
        const result = await axios.get(baseURL + `/getAll-departmentName`)
        if (result) {
            return callback(null, result);
        }
    } catch (error) {
        return callback(error, null);
    }

}


export const getDocFile = async (complianceId, callback) => {
    axios.get(`http://localhost:8080/users/download/demo1jpeg`)
        .then(response => response.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob);
            return callback(null, url);
        })
        .catch(error => console.log(error));

}



export const updateTransactionAdminDoc = async (data, callback) => {
    console.log('api-call')
    const formData = new FormData();
    // const fileType = data.uri.split('.').pop().split(/\#|\?/)
    let currentTimeStamp = Date.now();
    var random = Math.floor(1000 + Math.random() * 9000)
    formData.append("adminUploadedDocFile", data.adminUploadFile.name);
    formData.append("id", data.id);
    formData.append("file", data.adminUploadFile);
    await axios({
        method: 'put',
        url: baseURL + `/update-transactionAdminDoc`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' } // 'Content-Type':'application/x-www-form-urlencoded'   'multipart/form-data'
    })
        .then(function (response) {
            return callback(null, response);
        })
        .catch(function (response) {
            return callback(null, response);
        })
}



export const getAllTransactionByCreatedBy = async (id,startDate,endDate,likeValue, callback) => {
    axios.get(baseURL + `/getAll-transactionByCreatedby/${id}/${startDate}/${endDate}/${likeValue}`)
        .then(response => {
            if (response.status === 200) {
                return callback(null, response);
            }
            else {
                return callback(null, response);
            }
        })
        .catch(error => {
            return callback(error, null)
        });
}



export const getCurrentTransactionBycomId = async (comId, callback) => {
    axios.get(baseURL + `/get-CurrentMonthTransactionByComId/${comId}`)
        .then(response => {
            if (response.status === 200) {
                return callback(null, response);
            }
            else {
                return callback(null, response);
            }
        })
        .catch(error => {
            return callback(error, null)
        });
}



export const getTransactionEffectiveDate = async (complianceId, callback) => {
    axios.get(baseURL +`/get-TransactionEffectiveDateByComplianceId/${complianceId}`)
        .then(response => {
            if (response.status === 200) {
                return callback(null, response);
            }
            else {
                return callback(null, response);
            }
        })
        .catch(error => {
            return callback(error, null)
        });
}


export const DeleteComplianceStatus = async (complianceId, callback) => {
   console.log("deleteing")
   console.log(complianceId)
    axios.put(baseURL + `/update-complianceIsDeletedStatusByComId/${complianceId}`)
        .then(response => {
            if (response.status === 200) {
               console.log("inside if")
                 console.log(response)
                return callback(null, response);
            }
            else {
                 console.log("inside else")
                 console.log(response)
                 return callback(null, response);
            }
        })
        .catch(error => {
            console.log(error)
            return callback(error, null)
        });
}

