// const { createStore, combineReducers } =require("redux");
import {createStore, combineReducers} from "redux";
import todoReducer from "./reducers/todo-reducer";
import userReducer from "./reducers/user-reducer";
import { createStoreHook } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
// import { configureStore } from '@reduxjs/toolkit'

const rootReducer= combineReducers({todoReducer,userReducer})

// const store = Stor
const store = configureStore({ reducer: rootReducer })


export default store;




 