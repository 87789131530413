import React, { useEffect } from 'react'
import { Button, Container, Col, Row, Table, Card, Form, Modal, InputGroup, Image } from 'react-bootstrap'
import { useState } from 'react';
import { insertEmailTemp, getAllEmilTemp, insertComplianceDetails } from '../util/api-call';
import Cookies from 'js-cookie';
import axios from "axios";
import { Route, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert } from '@mui/material';
import './CreateComplianceStyle.css'




function CreateCompliance() {
    const [show, setShow] = useState(false);
    const [tempName, setTempName] = useState();
    const [mailList, setMailList] = useState('API');
    const [mailSubject, setMailSubject] = useState();
    const [mailBody, setMailBody] = useState();
    const [uri, setUri] = useState();
    const [docFileName, setDocFileName] = useState('');
    const [complianceName, setComplianceName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [emailTemp, setEmailTemp] = useState('');
    const [complianceType, setComplianceType] = useState('monthly');
    const [isComment, setIsComment] = useState(true);
    const [isNotificationBefore7Days, setIsNotificationBefore7Days] = useState(false);
    const [isNotificationBefore3Days, setIsNotificationBefore3Days] = useState(true);
    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [users, setUsers] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [department, setDepartment] = useState();
    const navigate = useNavigate();
    const [isEmailTemplateCreated, setIsEmailTemplateCreated] = useState(false);


    // let demo = location.state.demo;
    const [sizes, setSizes] = useState([]);
    const location = useLocation()
    const [open, setOpen] = React.useState(false);

    const handleCloseLoader = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    // let tempData = params.tempData.match('a');
    let payload = {
        temp_name: tempName,
        email_list: mailList,
        email_subject: mailSubject,
        email_body: mailBody,
        created_by: null,
        created_at: "hsh"
    }

    let payloadA = {
        uri: uri,
        // doc_file_name:docFileName,
        compliance_name: complianceName,
        user_email: userEmail,
        start_date: startDate < 10 ? '0' + startDate : startDate,
        end_date: endDate < 10 ? '0' + endDate : endDate,
        email_temp: emailTemp,
        compliance_type: complianceType,
        is_comment: isComment,
        is_notification_before_7_days: isNotificationBefore7Days,
        is_notification_before_3_days: isNotificationBefore3Days,
        department: department,
        created_by: null,
        created_at: null
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const insertEmailTemplate = () => {
    //     console.log(location.state.demo);
    // }


    const [validated, setValidated] = useState(false);
    const [validatedA, setValidatedA] = useState(false);

    const handleSubmitA = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let name = Cookies.get('arka');
            // console.log(name);

            if (name) {
                let t = JSON.parse(name)
                payloadA.created_by = t.id;
                insertComplianceDetails(payloadA, (error, result) => {
                    if (error) {
                        console.log(error);
                    } else {
                        console.log(result);
                        navigate(-1);
                    }
                });
            }

        };
        setValidatedA(true);

    }


    const handleSubmit = async (event) => {

        setOpen(true)

        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setOpen(false)

            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let name = Cookies.get('arka');
            //console.log(name);

            if (name) {
                let t = JSON.parse(name)
                payload.created_by = t.id;
                let v = await insertEmailTemp(payload);
                //console.log(payload);
                setTimeout(() => {
                    handleClose()
                    setOpen(false)
                    setIsEmailTemplateCreated(true)
                }, 1000);
            }

        };
        setValidated(true);

    }


    const handleCheckBox1 = async (event) => {
        let value = event.target.checked;
        //console.log(value)
        setIsComment(value);
    }
    const handleCheckBox2 = async (event) => {
        let value = event.target.checked;
        //console.log(value)
        setIsNotificationBefore7Days(value);
    }
    const handleCheckBox3 = async (event) => {
        let value = event.target.checked;
        //console.log(value)
        setIsNotificationBefore3Days(value);
    }

    // const handleSelectFile = async (e) => {
    // let file = event.target.files[0];
    // setUri(event.target.files[0]);
    // console.log(uri);
    function handleSelectFile(e) {
        const file = e.target.files[0];
        setUri(file);
        //console.log(uri);
        // return new Promise((resolve, reject) => {
        //     const reader = new FileReader();

        //     reader.onload = (event) => {
        //         setUri(event.target.result);
        //         console.log(uri);
        //         resolve(event.target.result);
        //     };

        //     reader.onerror = (err) => {
        //         reject(err);
        //     };

        //     reader.readAsDataURL(file);
        // });


    }


    useEffect(() => {
        setOpen(true)
        setSizes(location.state.demo);
        setUsers(location.state.userList)
        setDepartmentList(location.state.departmentList)
        // console.log(location.state.userList)
        //console.log(location.state.departmentList)

        setTimeout(() => {
            setOpen(false)
        }, 200);
    }, [])




    return (
        <>
            <Container className='shadow-lg border' style={{ padding: 10, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '20px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleCloseLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Row style={{ padding: 5, marginBottom: '30px', backgroundColor: '#06444a', marginRight: -9, marginLeft: -9 }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <p className='CC-HEADING' style={{ margin: 0, color: 'white' }}>CREATE COMPLIANCE</p>
                    </Col>

                </Row>

                <div>
                    {isEmailTemplateCreated ? <Alert onClose={() => { setIsEmailTemplateCreated(false) }}>Email template created!</Alert> : null}

                    <Row>
                        <Col>
                            <Form noValidate validated={validatedA} onSubmit={handleSubmitA}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridcomplianceName">
                                        <Form.Label className='CC-LABEL'>Compliance Name</Form.Label>
                                        <InputGroup hasValidation>

                                            <Form.Control type="text" placeholder="Enter Name"
                                                className='CC-INPUT'
                                                required
                                                value={complianceName}
                                                onChange={(event) => setComplianceName(event.target.value)} />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGriduserEmail">
                                        <Form.Label className='CC-LABEL'>Select User</Form.Label>
                                        <Form.Select
                                            className='CC-INPUT'
                                            placeholder='Select user'
                                            value={userEmail}
                                            onChange={(e) => setUserEmail(e.target.value)}
                                        >
                                            {users.map(usr => (
                                                <option>{usr}</option>
                                            ))}

                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridDepartment">
                                        <Form.Label className='CC-LABEL'>Select Department</Form.Label>
                                        <Form.Select
                                            className='CC-INPUT'
                                            placeholder='Select Department'
                                            value={department}
                                            onChange={(e) => setDepartment(e.target.value)}
                                        >
                                            {departmentList.map(usr => (
                                                <option>{usr}</option>
                                            ))}

                                        </Form.Select>
                                    </Form.Group>
                                </Row>


                                <Row className="mb-3">
                                    <Col as={Col}>
                                        <Row>
                                            <Form.Group as={Col} controlId="formGridstartDate">
                                                <Form.Label className='CC-LABEL'>Start Day</Form.Label>
                                                <Form.Control type="number"
                                                    className='CC-INPUT'
                                                    placeholder='Start date'
                                                    required
                                                    value={startDate}
                                                    onChange={(event) => setStartDate(event.target.value)}
                                                />
                                            </Form.Group>


                                            <Form.Group as={Col} controlId="formGridendDate">
                                                <Form.Label className='CC-LABEL'>End Day</Form.Label>
                                                <Form.Control type="number"
                                                    className='CC-INPUT'
                                                    placeholder='End date'
                                                    required
                                                    value={endDate}
                                                    onChange={(event) => setEndDate(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Row>


                                    </Col>

                                    <Form.Group as={Col} controlId="formGridemailTemp">
                                        <Form.Label className='CC-LABEL'>Select Email Template</Form.Label>
                                        <Form.Select defaultValue="Choose..."
                                            className='CC-INPUT'
                                            value={emailTemp}
                                            onChange={(e) => setEmailTemp(e.target.value)}
                                        >
                                            {sizes.map(size => (
                                                <option>{size}</option>
                                            ))}
                                        </Form.Select>

                                    </Form.Group>
                                </Row>

                                <Row className="mb-5">
                                    <Col as={Col}>
                                        <Form.Group className="mb-3" id="formGridCheckbox1">
                                            <InputGroup hasValidation>
                                                <Form.Check className='CC-LABEL' type="radio" value='quarterly' checked={complianceType === 'quarterly'} onChange={(event) => setComplianceType(event.target.value)} required label="Quarterly" name="formHorizontalRadios" id="formHorizontalRadios1" />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group className="mb-3" id="formGridCheckbox2">
                                            <Form.Check className='CC-LABEL' type="radio" value='monthly' checked={complianceType === 'monthly'} onChange={(event) => setComplianceType(event.target.value)} required label="Monthly" name="formHorizontalRadios" id="formHorizontalRadios1" />
                                        </Form.Group>

                                        <Form.Group controlId="formGridState3" style={{ padding: 10 }}>
                                            <p style={{ fontWeight: 600 }}><a class="link-opacity-100-hover" onClick={handleShow}>Create Email Template</a></p>
                                        </Form.Group>
                                    </Col>

                                    <Col as={Col} >
                                        <Form.Group className="mb-3" id="formGridCheckbox4">
                                            <Form.Check className='CC-LABEL' type="checkbox" checked={isComment} onChange={e => handleCheckBox1(e)} label="Comment option is available" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" id="formGridCheckbox5">
                                            <Form.Check className='CC-LABEL' type="checkbox" checked={isNotificationBefore7Days} onChange={e => handleCheckBox2(e)} label="Notification before 7 days" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" id="formGridCheckbox6">
                                            <Form.Check className='CC-LABEL' type="checkbox" checked={isNotificationBefore3Days} onChange={e => handleCheckBox3(e)} label="Notification before 3 days" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit" style={{ backgroundColor: '#06444a', fontSize: 'small' }}>
                                    SAVE
                                </Button>
                            </Form>
                        </Col>
                        <Col sm={3} className='border-start' style={{ padding: 25, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <Card style={{ width: '10rem', borderBottom: uri ? '5px solid green' : '0px solid green' }}>
                                <Card.Img variant="top" src="https://d27jswm5an3efw.cloudfront.net/app/uploads/2019/02/doc-document-file-11.jpg" />
                                <Card.Body>
                                    <Card.Title>{uri ? uri.name : null}</Card.Title>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Control type="file" onChange={(e) => handleSelectFile(e)} />
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='EMAILTEMPHEADING'>Create Email Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='EMAILTEMPLABEL'>Template name</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Template name"
                                    autoFocus
                                    required
                                    value={tempName}
                                    onChange={(event) => setTempName(event.target.value)}
                                />
                            </InputGroup>

                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="To"
                                autoFocus
                                required
                                value={mailList}
                                onChange={(event) => setMailList(event.target.value)}

                            />
                            <Form.Text className="text-muted">
                                Use comma (,) for two or more email.
                            </Form.Text>
                        </Form.Group> */}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label className='EMAILTEMPLABEL'>Subject</Form.Label>
                            <Form.Control
                                type="subject"
                                placeholder="Subject"
                                autoFocus
                                required
                                value={mailSubject}
                                onChange={(event) => setMailSubject(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label className='EMAILTEMPLABEL'>Email Body</Form.Label>

                            <Form.Control as="textarea" rows={3}
                                required
                                value={mailBody}
                                onChange={(event) => setMailBody(event.target.value)}

                            />

                        </Form.Group>
                        <Button style={{ fontSize: 'small', backgroundColor: '#06444a' }} variant="primary" type='submit'>
                            SAVE CHANGES
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ fontSize: 'small' }} variant="secondary" onClick={handleClose}>
                        CLOSE
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}


export default CreateCompliance