import React from 'react'

function Protected(props) {
    const {Component} = props;

  return (
    <div>
         {false ? <Component/> : <h1>hii</h1>}
    </div>
  )
}

export default Protected