import React, { useEffect, useState } from 'react'
import { Navbar, Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setUser } from '../redux/actions/user';
import Cookies from 'js-cookie';
import { addTodo } from '../redux/actions/todo';
import { Avatar } from '@mui/material';
import jwtDecode from 'jwt-decode';


function Header({ addTodo, todos, setUser, users }) {
    const [profilePic, setProfile] = useState('https://cdn1.iconfinder.com/data/icons/online-shopping-14/100/Contact-05-512.png');

    const logOut = () => {
        Cookies.remove('arka');
        setUser('system')
        addTodo('NO')

    }

    useEffect(() => {
        let savedCookies = Cookies.get('arka');
        if (savedCookies) {
            //console.log(savedCookies);
            let t = JSON.parse(savedCookies)
            // var decoded = jwtDecode(t.token);
            setProfile(t.profile);
            //console.log(t)

        }
    }, [])
    return (
        <div>
            <Navbar className='shadow-lg'>
                <Container>
                    <img
                        src="https://www.arkaholdings.com/image/layout_set_logo?img_id=2216379&t=1704171854252"
                        width="45"
                        height="30"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                    {/* <Navbar.Brand href="#home">Branch Compliance Portal</Navbar.Brand> */}
                    <Navbar.Toggle />
                    {(todos === 'user') || (todos === 'admin') ? <Navbar.Collapse className="justify-content-end">
                        <div style={{ marginLeft: 7, marginRight: 7 }}>
                            <Avatar alt="Remy Sharp" src={profilePic} />
                        </div>
                        <Navbar.Text>
                            {users}
                        </Navbar.Text>
                        <div>
                            <Button variant="link" onClick={() => logOut()}>Logout</Button>
                        </div>
                    </Navbar.Collapse> : null}
               </Container>
            </Navbar>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log(state);
 return { todos: state.todoReducer, users: state.userReducer }
}

const mapDispatchToProps = (dispatch) => ({
    addTodo: (todo) => { dispatch(addTodo(todo)) },
    setUser: (name) => { dispatch(setUser(name)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)