import React, { useEffect } from 'react'
import { Container, Col, Row, Table, Card, Form } from 'react-bootstrap'
import { useState } from 'react';
import { getComplianceByid, getTransactionDataByComId, getDocFile, updateTransactionAdminDoc, DeleteComplianceStatus } from '../util/api-call';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './ViewComplianceStyle.css';
import DeleteLoader from '../components/DeleteLoader';
import { VscTrash,VscEdit } from "react-icons/vsc";


function ViewCompliance() {
    const location = useLocation();
    const navigate = useNavigate();

    const [comName, setComName] = useState('');
    const [user, setUser] = useState('');
    const [comType, setComType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [commentOption, setCommentOption] = useState('');
    const [NotificationBefore7days, setNotificationBefore7Days] = useState('');
    const [NotificationBefore3days, setNotificationBefore3Days] = useState('');
    const [emailTemplate, setEmailTemplate] = useState('');
    const [docFile, setDocFile] = useState('');
    const [comList, setComList] = useState([]);
    const [isApiHaveData, SetIsApiHaveData] = useState('');
    const [tempList, setTempList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [department, setDepartment] = useState('');
    const [departmentList, setDepartmentList] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [adminUploadFile, setAdminUploadedFile] = useState();
    const [adminUploadModelOpen, setAdminUploadModelOpen] = useState(false);
    const [trId, setTrId] = useState('');
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(true);
    const [showDeleteLoader, setShowDeleteLoader] = useState(true);

    const handleOpenAdminUpload = () => setAdminUploadModelOpen(true);
    const handleCloseAdminUpload = () => setAdminUploadModelOpen(false);



    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const upLoadTrAdminDoc = async (id) => {
        setIsDeleteModalShow(true);
        let ID = await id;
        setTrId(ID);
        setAdminUploadModelOpen(true);
    }

    const DeleteMOdal = async () => {
        setIsDeleteModalShow(false);
        setAdminUploadModelOpen(true);
    }



    const [comId, setComId] = useState('');
    const comPayload = {
        comName: comName,
        user: user,
        comType: comType,
        startDate: startDate,
        endDate: endDate,
        commentOption: commentOption,
        NotificationBefore3days: NotificationBefore3days,
        NotificationBefore7days: NotificationBefore7days,
        emailTemplate: emailTemplate,
        comId: comId,
        docFile: docFile
    }


    const callGetemp = async () => {
        setOpen(true)
        getComplianceByid(comId, (error, result) => {
            if (error) {
                console.log(error);
            } else {
                console.log(result.data.data[0]);
                setComName(result.data.data[0].compliance_name);
                setUser(result.data.data[0].user_email);
                setComType(result.data.data[0].compliance_type);
                setStartDate(result.data.data[0].start_date);
                setEndDate(result.data.data[0].end_date);
                setCommentOption(result.data.data[0].is_comment);
                setNotificationBefore7Days(result.data.data[0].is_notification_before_7_days);
                setNotificationBefore3Days(result.data.data[0].is_notification_before_3_days);
                setDepartment(result.data.data[0].department)
                setEmailTemplate(result.data.data[0].email_temp);
                setDocFile(result.data.data[0].doc_file_name)
                setTimeout(() => {
                    setOpen(false)
                }, 1000);
            }
        })
    }

    const calltransactionAPIbyComId = async () => {
        setOpen(true)
        getTransactionDataByComId(comId, (error, result) => {
            if (error) {
                setTimeout(() => {
                    setOpen(false)
                }, 1000);
                console.log(error);
            } else {
                console.log(result.data.data);
                setTimeout(() => {
                    setOpen(false)
                }, 1000)
                if (result.data.data == "notAvailable") {
                    SetIsApiHaveData('no')
                    console.log(result.data.data)
                } else {
                    SetIsApiHaveData('yes')
                    setComList(result.data.data)
                }
            }
        })
    }

    const validateDownloadDoc = async (fileName) => {
        let f = await fileName
        callDocumentAPI(f);

    }

    const callDocumentAPI = async (fileName) => {
        setOpen(true)
        fetch('https://compliance.arkafincap.com/users/download/' + fileName)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                setFileData(url);
                setOpen(false)
            })
            .catch(error => console.log(error));
    }

    function handleSelectFile(e) {
        const file = e.target.files[0];
        setAdminUploadedFile(file);
    }

    function callAdminUploadDoc() {
        setOpen(true);
        const payloadB = {
            id: trId,
            adminUploadFile: adminUploadFile
        }
        updateTransactionAdminDoc(payloadB, (error, result) => {
            if (error) {
                console.log(error)
                setOpen(false);
                handleCloseAdminUpload();
            } else {
                console.log(result);
                setOpen(false);
                handleCloseAdminUpload();
                calltransactionAPIbyComId();
            }
        })
    }


    function callDeleteComplianceAPI() { 
        setOpen(true);
         console.log(comId);
         console.log("delete compliance");


        DeleteComplianceStatus(comId, (error, result) => {
            if (error) {
                console.log(error)
                setOpen(false);
                handleCloseAdminUpload();
            } else {
                setShowDeleteLoader(false);
                console.log(result);
                setOpen(false);
                setTimeout(() => {
                    setShowDeleteLoader(true)
                    handleCloseAdminUpload();
                    navigate(-1);
                }, 2000)
            }
        })
    }

    useEffect(() => {

        setOpen(true);
        setComId(location.state.Id);
        setTempList(location.state.demo);
        setUserList(location.state.userList);
        setDepartmentList(location.state.departmentList);
        callGetemp();
        calltransactionAPIbyComId();
        callDocumentAPI()
    }, [comId])


    return (
        <>
            <Container className='shadow-lg border rounded' style={{ padding: 10, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '20px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Row style={{ padding: 5, marginBottom: '30px', backgroundColor: 'white', marginRight: -9, marginLeft: -9, border: '0px solid black' }}>
                    <Col xs={6} md={6} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className='h5 HEADING' style={{ margin: 0, color: 'black' }}>VIEW COMPLIANCE</p>
                        {/* <a class="link-opacity-100-hover" href="#">Edit</a> */}
                    </Col>
                    <Col xs={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{  fontSize: 'large', color: 'black',minWidth:'0px',marginLeft:'5px' }} bsStyle="link" onClick={() => navigate("/viewCompliance/Edit", { state: { comPayload, userList, tempList, departmentList, department } })}> <VscEdit/> </Button>
                        <Button style={{  fontSize: 'large', color: 'black',minWidth:'0px',marginLeft:'5px' }} bsStyle="link" onClick={() => DeleteMOdal()} > <VscTrash/> </Button>
                    </Col>
                </Row>


                <Container>
                    <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                        <Col sm={8}>
                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Compliance Name
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {comName}
                                </Col>

                            </Row>

                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    User
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {user}
                                </Col>
                            </Row>

                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Compliance Type
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {comType}
                                </Col>
                            </Row>

                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Department
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {department}
                                </Col>
                            </Row>

                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Start/End Date
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {startDate} - {endDate}
                                </Col>
                            </Row>

                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Comment Option
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {commentOption}
                                </Col>
                            </Row>

                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Notification before 7 days
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {NotificationBefore7days}
                                </Col>
                            </Row>

                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Notification before 3 days
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {NotificationBefore3days}
                                </Col>
                            </Row>


                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Email Template
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    {emailTemplate}
                                </Col>
                            </Row>


                            <Row className='justify-content-md-center' style={{ marginBottom: 10 }}>
                                <Col className='VC-COLHEADING' sm={6} style={{ fontWeight: 'bold' }}>
                                    Status
                                </Col>
                                <Col className='VC-COLVALUE' sm={6} style={{ backgroundColor: '#F8F8FF' }}>
                                    <i class="bi bi-check-circle-fill">Running</i>
                                </Col>
                            </Row>

                        </Col>
                        <Col sm={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <Card style={{ width: '10rem', borderBottom: docFile ? '5px solid green' : '0px solid green' }}>
                                <Card.Img variant="top" src="https://d27jswm5an3efw.cloudfront.net/app/uploads/2019/02/doc-document-file-11.jpg" />
                                <Card.Body>
                                    <Card.Title><a href={fileData} onClick={() => callDocumentAPI(docFile)} download={docFile}>{docFile}</a></Card.Title>
                                </Card.Body>
                            </Card>

                            {/* <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                                <div style={{ width: 20, height: 20, border: '1px solid black', borderRadius: 100, backgroundColor: '#C70039 ' }}></div>

                                <div style={{ width: 5, height: 60, border: '1px solid black', backgroundColor: 'yellow' }}></div>
                                <div style={{ width: 20, height: 20, border: '1px solid black', borderRadius: 100, backgroundColor: '#AAFF00' }}></div>
                                <div style={{ width: 5, height: 60, border: '1px solid black', backgroundColor: 'yellow' }}></div>
                                <div style={{ width: 20, height: 20, border: '1px solid black', borderRadius: 100, backgroundColor: '#AAFF00' }}></div>
                                <div style={{ width: 5, height: 60, border: '1px solid black', backgroundColor: 'yellow' }}></div>
                                <div style={{ width: 20, height: 20, border: '1px solid black', borderRadius: 100, backgroundColor: 'lightgray' }}></div>
                            </Col> */}
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Modal
                open={adminUploadModelOpen}
                onClose={handleCloseAdminUpload}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {
                    isDeleteModalShow ?

                        <Box sx={style}>
                            <Container>
                                <p style={{ fontSize: 'small', color: 'black' }}>Admin Upload</p>
                                <input style={{ fontSize: 'small' }} type="file" id="myFile" onChange={(e) => setAdminUploadedFile(e.target.files[0])} name="filename" />
                            </Container>
                            <Typography id="modal-modal-description" style={{ alignItems: 'center', textAlign: 'center', marginTop: 10 }} sx={{ mt: 1 }}>
                                <Button disabled={!adminUploadFile} onClick={() => callAdminUploadDoc()} style={{ color: 'white', backgroundColor: adminUploadFile ? 'green' : '#06444a', font: 'small-caption', alignSelf: 'center' }}>Upload</Button>
                            </Typography>
                        </Box> : showDeleteLoader ?
                            <Box sx={style}>
                                <Container>
                                    <p style={{ fontSize: 'small', color: 'black', fontFamily:'monospace' }}>Delete compliance will change your report !</p>
                                </Container>
                                <Typography id="modal-modal-description" style={{ alignItems: 'center', textAlign: 'center', marginTop: 10 }} sx={{ mt: 1 }}>
                                    <Button onClick={() => callDeleteComplianceAPI()} style={{ color: 'white', backgroundColor: '#06444a', font: 'small-caption', alignSelf: 'center' }}>delete</Button>
                                </Typography>
                            </Box> :
                            <Box sx={style}>
                                <Container style={{display:'flex',justifyContent:'center'}}>
                                    <DeleteLoader />
                                </Container>
                            </Box>}
            </Modal>



            <Container className='shadow-lg border' style={{ padding: 10, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '50px' }}>
                <Row style={{ padding: 5, marginBottom: '30px', backgroundColor: 'white', marginRight: -9, marginLeft: -9, border: '0px solid black' }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <p className='h5 HEADING' style={{ margin: 0, color: 'black' }}>TRANSACTION</p>
                    </Col>
                </Row>

                <Container style={{ 'overflow-x': 'auto' }}>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th className='TABLEHEADER' scope="col">S.N</th>
                                <th className='TABLEHEADER' scope="col">Compliance Name</th>
                                <th className='TABLEHEADER' scope="col">Department</th>
                                <th className='TABLEHEADER' scope="col">Org Doc</th>
                                <th className='TABLEHEADER' scope="col">User uploaded Doc</th>
                                <th className='TABLEHEADER' scope="col">Admin uploaded doc</th>
                                <th className='TABLEHEADER' scope="col">Start Date</th>
                                <th className='TABLEHEADER' scope="col">End Date</th>
                                <th className='TABLEHEADER' scope="col">Comment</th>
                                <th className='TABLEHEADER' scope="col">Status</th>
                                <th className='TABLEHEADER' scope="col">Submited on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isApiHaveData == 'yes' ?
                                comList.map((number, i) => {
                                    return (
                                        <tr disabled={true}>
                                            <td className='TABLECOLUMN'>{i + 1}</td>
                                            <td className='TABLECOLUMN'>{number.comName}</td>
                                            <td className='TABLECOLUMN'>{number.department}</td>
                                            {/* <td><Card.Img variant="top" src={fileData} height={50} width={100} /></td> */}
                                            <td className='TABLECOLUMN' > {<a href={number.orgDocFile == 'Not uploaded' ? null : fileData} onClick={() => number.orgDocFile == 'Not uploaded' ? null : callDocumentAPI(number.orgDocFile)} download={number.orgDocFile}>{number.orgDocFile}</a>}</td>
                                            <td className='TABLECOLUMN'> {<a href={fileData} onClick={() => number.uploadedDocFile == 'Not uploaded' ? null : callDocumentAPI(number.uploadedDocFile)} download={number.uploadedDocFile}>{number.uploadedDocFile}</a>}</td>
                                            <td className='TABLECOLUMN'><Button onClick={() => upLoadTrAdminDoc(number.id)} style={{ backgroundColor: '#06444a', fontSize: 'xx-small', color: 'white', padding: 1 }}>Upload</Button>  <a href={fileData} onClick={() => number.adminUploadedDocFile == 'Not uploaded' ? null : callDocumentAPI(number.adminUploadedDocFile)} download={number.adminUploadedDocFile}>{number.adminUploadedDocFile}</a></td>
                                            <td className='TABLECOLUMN'>{moment(number.startDate).format("DD/MM/YYYY")}</td>
                                            <td className='TABLECOLUMN'>{moment(number.endDate).format("DD/MM/YYYY")}</td>
                                            <td className='TABLECOLUMN'>{number.comment}</td>
                                            <td className='TABLECOLUMN' style={{ color: number.status == 'not submitted' ? 'gray' : number.status == 'submitted' ? 'green' : 'orange' }}>{number.status == 'not submitted' ? 'Not Submitted' : number.status == 'submitted' ? 'Submitted' : number.status}</td>
                                            <td className='TABLECOLUMN'>{moment(number.submitted_on).format("lll") == 'Invalid date' ? '...' : moment(number.submitted_on).format("lll")}</td>
                                        </tr>
                                    )
                                }) : "Empty Table"
                            }
                        </tbody>
                    </Table>
                </Container>



                {/* <Footer /> */}

            </Container>
        </>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};

export default ViewCompliance