import React, { useEffect } from 'react'
import { Button, Container, Col, Row, Table, Card, Form, Modal, InputGroup } from 'react-bootstrap'
import { useState } from 'react';
import { insertEmailTemp, getAllEmilTemp, insertComplianceDetails, updateComplianceInfo } from '../util/api-call';
import Cookies from 'js-cookie';
import axios from "axios";
import { Route, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './EditComplianceStyle.css';




function EditCompliance() {
    const [show, setShow] = useState(false);
    const [tempName, setTempName] = useState();
    const [mailList, setMailList] = useState();
    const [mailSubject, setMailSubject] = useState();
    const [mailBody, setMailBody] = useState();
    const [uri, setUri] = useState('');
    const [docFileName, setDocFileName] = useState('');
    const [complianceName, setComplianceName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [emailTemp, setEmailTemp] = useState('');
    const [complianceType, setComplianceType] = useState('monthly');
    const [isComment, setIsComment] = useState(false);
    const [isNotificationBefore7Days, setIsNotificationBefore7Days] = useState(false);
    const [isNotificationBefore3Days, setIsNotificationBefore3Days] = useState(false);
    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [comId, setComId] = useState('');
    const [userList, setUserList] = useState([]);
    const [tempList, setTempList] = useState([])
    const [departmentList, setDepartmentList] = useState([]);
    const [department, setDepartment] = useState('');

    const navigate = useNavigate();


    // let demo = location.state.demo;
    const [sizes, setSizes] = useState([]);
    const location = useLocation()
    // let tempData = params.tempData.match('a');
    const [open, setOpen] = React.useState(false);

    const handleCloseLoader = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    let payload = {
        temp_name: tempName,
        email_list: mailList,
        email_subject: mailSubject,
        email_body: mailBody,
        created_by: null,
        created_at: "hsh"
    }
    let payloadA = {
        uri: uri,
        doc_file_name: docFileName,
        compliance_name: complianceName,
        user_email: userEmail,
        start_date: startDate,
        end_date: endDate,
        email_temp: emailTemp,
        compliance_type: complianceType,
        is_comment: isComment,
        is_notification_before_7_days: isNotificationBefore7Days,
        is_notification_before_3_days: isNotificationBefore3Days,
        department: department,
        id: comId
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const insertEmailTemplate = () => {
    //     console.log(location.state.demo);
    // }


    const [validated, setValidated] = useState(false);
    const [validatedA, setValidatedA] = useState(false);

    const handleSubmitA = async (event) => {
        event.preventDefault();
        setOpen(true)

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setOpen(false)
        }
        else {
            setOpen(true)
            let name = Cookies.get('arka');
            // console.log(name);

            if (name) {
                let t = JSON.parse(name)
                payloadA.created_by = t.id;
                console.log(payloadA)
                updateComplianceInfo(payloadA, (error, result) => {
                    if (error) {
                        setOpen(false)
                        //console.log(error);
                    } else {
                        //console.log(result);
                        setOpen(false)
                        navigate(-1);
                    }
                });
            }

        };
        setValidatedA(true);

    }


    const handleSubmit = async (event) => {
        setOpen(true)
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setOpen(false)
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setOpen(true)

            let name = Cookies.get('arka');
            //console.log(name);

            if (name) {
                let t = JSON.parse(name)
                payload.created_by = t.id;
                let v = await insertEmailTemp(payload);
                //console.log(payload);
                setOpen(false)
            }

        };
        setValidated(true);

    }


    const handleCheckBox1 = async (event) => {
                 setIsComment(!isComment);
        // if (isComment == 'true') {
        //     setIsComment('false');
        // } else {
        //     setIsComment('true');

        // }
    }
    const handleCheckBox2 = async (event) => {
        setIsNotificationBefore7Days(!isNotificationBefore7Days);
        // if (isNotificationBefore7Days == 'true') {
        //     setIsNotificationBefore7Days(!isNotificationBefore7Days);
        // } else {
        //     setIsNotificationBefore7Days('true');
        // }
        // let value = event.target.checked;
        // console.log(event.target.checked)
        //setIsNotificationBefore7Days(event.target.checked);
    }
    const handleCheckBox3 = async (event) => {
        setIsNotificationBefore3Days(!isNotificationBefore3Days);
        // if (isNotificationBefore3Days == 'true') {
        //     setIsNotificationBefore3Days('false');
        // } else {
        //     setIsNotificationBefore3Days('true');
        // }
        // let value = event.target.checked;
        // console.log(event.target.checked)
        // setIsNotificationBefore3Days(event.target.checked);
    }

    const handleSelectFile = async (event) => {
        const file = event.target.files[0];
        // setDocFileName(file);
        setUri(file)
        setDocFileName(file.name);
        //console.log(file);
    }


    useEffect(() => {
        // setSizes(location.state.demo);
        // handleCheckBox1(location.state.comPayload.commentOption);
        setOpen(true)
        //console.log(location.state.comPayload.commentOption)
        setUserList(location.state.userList);
        setTempList(location.state.tempList)
        setComplianceName(location.state.comPayload.comName);
        setComplianceType(location.state.comPayload.comType);
        setDocFileName(location.state.comPayload.docFile);
        setEmailTemp(location.state.comPayload.emailTemplate);
        setUserEmail(location.state.comPayload.user);
        setEndDate(location.state.comPayload.endDate);
        setStartDate(location.state.comPayload.startDate);
        // setIsComment(location.state.comPayload.commentOption);
        // setIsNotificationBefore3Days(location.state.comPayload.NotificationBefore3days);
        // setIsNotificationBefore7Days(location.state.comPayload.NotificationBefore7days);
        setDepartment(location.state.department)
        setDepartmentList(location.state.departmentList)
        setComId(location.state.comPayload.comId)

        // console.log(location.state.comPayload.commentOption);
        // setTimeout(() => {
        //     console.log(location.state.comPayload.NotificationBefore3days);
        //     setIsComment(location.state.comPayload.commentOption);
        //     setIsNotificationBefore3Days(location.state.comPayload.NotificationBefore3days);
        //     setIsNotificationBefore7Days(location.state.comPayload.NotificationBefore7days);
        //     setOpen(false)

        // }, 1000);
    }, [])



    useEffect(() => {
        // setSizes(location.state.demo);
        setTimeout(() => {
            //console.log(location.state.comPayload.NotificationBefore3days);
            setIsComment(location.state.comPayload.commentOption);
            setIsNotificationBefore3Days(location.state.comPayload.NotificationBefore3days);
            setIsNotificationBefore7Days(location.state.comPayload.NotificationBefore7days);
            setOpen(false)
        }, 1000);


    }, [])



    return (
        <>
            <Container className='shadow-lg border' style={{ padding: 10, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '20px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleCloseLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* <Row style={{ padding: 5, marginBottom: '30px', backgroundColor: '#06444a', marginRight: -9, marginLeft: -9 }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <p  className='h5 EC-HEADING' style={{ margin: 0, color: 'white' }}>Edit Compliance</p>
                    </Col>
                </Row> */}
                <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginBottom: '10px' }}>
                    <Row style={{ padding: 5, marginBottom: '0px', backgroundColor: '#06444a', marginRight: 0, marginLeft: 0, marginRight: -10, marginLeft: -10 }}>
                        <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <p className='h5 HEADING' style={{ margin: 0, color: 'white', fontSize: 'medium' }}>EDIT COMPLIANCE</p>
                        </Col>
                    </Row>
                </Container>

                <div>
                    <Row>
                        <Col>
                            <Form noValidate validated={validatedA} onSubmit={handleSubmitA}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridcomplianceName">
                                        <Form.Label className='EC-LABEL'>Compliance Name</Form.Label>
                                        <InputGroup hasValidation>

                                            <Form.Control className='EC-INPUT' type="text" placeholder="Enter Name"
                                                required
                                                defaultValue={complianceName}

                                                onChange={(event) => setComplianceName(event.target.value)} />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGriduserEmail">
                                        <Form.Label className='EC-LABEL'>Select User</Form.Label>
                                        <Form.Select
                                            className='EC-INPUT'
                                            placeholder='Select user'
                                            value={userEmail}
                                            onChange={(e) => setUserEmail(e.target.value)}
                                        >
                                            {userList.map(usr => (
                                                <option>{usr}</option>
                                            ))}
                                            {/* <option>mayur@arkafincap.com</option> */}
                                        </Form.Select>
                                    </Form.Group>


                                    <Form.Group as={Col} controlId="formGridDepartment">
                                        <Form.Label className='EC-LABEL'>Select Department</Form.Label>
                                        <Form.Select
                                            className='EC-INPUT'
                                            placeholder='Select Department'
                                            value={department}
                                            onChange={(e) => setDepartment(e.target.value)}
                                        >
                                            {departmentList.map(usr => (
                                                <option>{usr}</option>
                                            ))}
                                            {/* <option>mayur@arkafincap.com</option> */}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>


                                <Row className="mb-3">
                                    <Col as={Col}>
                                        <Row>
                                            <Form.Group as={Col} controlId="formGridstartDate">
                                                <Form.Label className='EC-LABEL'>Start Day</Form.Label>
                                                <Form.Control type="number"
                                                    className='EC-INPUT'
                                                    placeholder='Start date'
                                                    required
                                                    defaultValue={startDate}
                                                    onChange={(event) => setStartDate(event.target.value)}
                                                />
                                            </Form.Group>


                                            <Form.Group as={Col} controlId="formGridendDate">
                                                <Form.Label className='EC-LABEL'>End Day</Form.Label>
                                                <Form.Control type="number"
                                                    className='EC-INPUT'
                                                    placeholder='End date'
                                                    required
                                                    defaultValue={endDate}
                                                    onChange={(event) => setEndDate(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Row>


                                    </Col>

                                    <Form.Group as={Col} controlId="formGridemailTemp">
                                        <Form.Label className='EC-LABEL'>Select Email Template</Form.Label>
                                        <Form.Select defaultValue="Choose..."
                                            className='EC-INPUT'
                                            value={emailTemp}
                                            onChange={(e) => setEmailTemp(e.target.value)}>
                                            {tempList.map(usr => (
                                                <option>{usr}</option>
                                            ))}
                                        </Form.Select>

                                    </Form.Group>
                                </Row>

                                <Row className="mb-5">
                                    <Col as={Col}>
                                        <Form.Group className="mb-3" id="formGridCheckbox1">
                                            <InputGroup hasValidation>
                                                <Form.Check className='EC-LABEL' type="radio" defaultValue='quarterly' checked={complianceType === 'quarterly'} onChange={(event) => setComplianceType(event.target.value)} required label="Quarterly" name="formHorizontalRadios" id="formHorizontalRadios1" />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group className="mb-3" id="formGridCheckbox2">
                                            <Form.Check className='EC-LABEL' type="radio" defaultValue='monthly' checked={complianceType === 'monthly'} onChange={(event) => setComplianceType(event.target.value)} required label="Monthly" name="formHorizontalRadios" id="formHorizontalRadios1" />
                                        </Form.Group>

                                        <Form.Group controlId="formGridState3" style={{ padding: 10 }}>
                                            <p style={{ fontWeight: 600 }}><a class="link-opacity-100-hover" onClick={handleShow}>Create Email Template</a></p>
                                        </Form.Group>
                                    </Col>

                                    <Col as={Col}>
                                        <Form.Group className="mb-3" id="formGridCheckbox4">
                                            {/* <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label="Check this switc"
                                                defaultChecked={isComment}
                                                value={isComment}
                                                onChange={e => setIsComment(e.target.checked)}
                                            /> */}
                                            <Form.Check className='EC-LABEL' type="checkbox" checked={isComment} onChange={e => handleCheckBox1(e)} label="Comment option is available" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" id="formGridCheckbox5">
                                            <Form.Check className='EC-LABEL' type="checkbox" defaultChecked={isNotificationBefore7Days} onChange={e => handleCheckBox2(e)} label="Notification before 7 days" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" id="formGridCheckbox6">
                                            <Form.Check className='EC-LABEL' type="checkbox" defaultChecked={isNotificationBefore3Days} onChange={e => handleCheckBox3(e)} label="Notification before 3 days" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit" style={{ backgroundColor: '#06444a', fontSize: 'small' }}>
                                    SAVE CHANGES
                                </Button>
                            </Form>
                        </Col>
                        <Col sm={4} className='border-start' style={{ padding: 25, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <Card style={{ width: '10rem', borderBottom: true ? '5px solid green' : '0px solid green' }}>
                                <Card.Img variant="top" src="https://d27jswm5an3efw.cloudfront.net/app/uploads/2019/02/doc-document-file-11.jpg" />
                                <Card.Body>
                                    <Card.Title></Card.Title>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Control type="file" onChange={(e) => handleSelectFile(e)} />
                                    </Form.Group>
                                    <div><p>{docFileName}</p></div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Email Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Template name</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Template name"
                                    autoFocus
                                    required
                                    value={tempName}
                                    onChange={(event) => setTempName(event.target.value)}
                                />
                            </InputGroup>

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="To"
                                autoFocus
                                required
                                value={mailList}
                                onChange={(event) => setMailList(event.target.value)}

                            />
                            <Form.Text className="text-muted">
                                Use comma (,) for two or more email.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                type="subject"
                                placeholder="Subject"
                                autoFocus
                                required
                                value={mailSubject}
                                onChange={(event) => setMailSubject(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Email Body</Form.Label>

                            <Form.Control as="textarea" rows={3}
                                required
                                value={mailBody}
                                onChange={(event) => setMailBody(event.target.value)}

                            />

                        </Form.Group>
                        <Button variant="primary" type='submit'>
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}


export default EditCompliance;