import React, { useEffect, useState } from 'react'
import { Button, Container, Col, Row, Table, Form } from 'react-bootstrap'
import { addTodo } from '../redux/actions/todo'
import { connect } from 'react-redux'
import UserHome from './UserHome'
import { useNavigate } from 'react-router-dom';
import { getAllEmilTemp, getAllUserInfo, getComplianceByCreatedBy, getAllDepartment, getAllTransactionByCreatedBy } from '../util/api-call'
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autotable from 'jspdf-autotable';
import layout_set_logo from '../img/layout_set_logo.png'
import Cookies from 'js-cookie';
import './AdminHomeStyle.css';



function AdminHome({ addTodo, todos }) {
    const user = [
        { "id": 0, "name": "IT compliance", "class": "abhijeet.tiwari@arka.com" },
        { "id": 1, "name": "operation compliance", "class": "mayur@arka.com" },
        { "id": 2, "name": "SME compliance", "class": "kausar@arkafincap" },
        { "id": 3, "name": "Digital compliance", "class": "nikita@arka.com" },
    ]
    const [comList, setComList] = useState(['']);
    const [demo, setDemo] = useState([])
    const [userList, setUserList] = useState([])
    const [departmentList, setDepartmentList] = useState([]);
    const [isTrApiHaveData, setIsTrApiHaveData] = useState('no');
    const [isApiHaveData, SetIsApiHaveData] = useState('no');
    const [transactionList, setTransactionList] = useState([]);
    const momeCurrentDate = moment();
    const momeFutureDate = moment(momeCurrentDate);
    const momeCurrentMonthStartDate = momeFutureDate.startOf('month').format('DD-MM-YYYY');
    const momecurrentMonthEndDate = momeFutureDate.endOf('month').format('DD-MM-YYYY');
    const [startDate, setStratDate] = useState(momeCurrentMonthStartDate);
    const [endDate, setEndDate] = useState(momecurrentMonthEndDate);
    const [created_by, setCreated_by] = useState('');
    const [likeValue, setLikeValue] = useState('nofilter');
    const [userLike, setUserLike] = useState('All User')
    const [departmentLike, setDepartmentLike] = useState('All Department')
    // const [department,setDepartment] = useState('');


    // let demo = [];
    // let tempData = "abhijeet";


    // const [todo, setTodo] = useState({
    //     title: '',s
    //     description: ''
    // })
    const [todo, setTodo] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(todo);
        addTodo(todo);
        setTodo('')
    }

    const callGetCompliance = async => {
        let name = Cookies.get('arka');
        let id = null;
        if (name) {
            let t = JSON.parse(name)
            setCreated_by(t.id);
            id = t.id;
        }

        getComplianceByCreatedBy(id, userLike, departmentLike, (error, resultt) => {
            if (error) {
                console.log(error);
            } else {
                if (resultt.data.data == "notAvailable") {
                    SetIsApiHaveData('no')
                    //console.log(resultt.data.data)
                } else {
                    //console.log(resultt);
                    setComList(resultt.data.data)
                    SetIsApiHaveData('yes')
                }

            }
        });
    }

    const callGetemp = async () => {
        setOpen(true);
        let name = Cookies.get('arka');
        if (name) {
            let t = JSON.parse(name)
            setCreated_by(t.id);
        }
        await getAllEmilTemp((error, result) => {
            if (error) {
                //console.log(error)
            } else {
                //console.log(result.data.data);
                for (var i in result.data.data) {
                    demo.push(result.data.data[i].temp_name);
                }
                callGetCompliance();
            }

        })
    }

    const calluserList = async () => {
        setOpen(true);
        let name = Cookies.get('arka');
        // console.log(name);

        if (name) {
            let t = JSON.parse(name)
            setCreated_by(t.id);
            // payload.created_by = t.id;
        }

        await getAllUserInfo((error, result) => {
            while (userList.length > 0) {
                userList.pop();
            }
            if (error) {
                //console.log(error);
            } else {
                //console.log(result);
                for (var i in result.data.data) {
                    userList.push(result.data.data[i].user_email);
                }
                setTimeout(() => {
                    setOpen(false)
                }, 500);
            }
        });

    }


    const callGetDepartment = async () => {
        // let dep = [] ;
        // setDepartmentList(dep);
        while (departmentList.length > 0) {
            departmentList.pop();
        }
        // departmentList.
        setOpen(true);
        await getAllDepartment((error, result) => {
            if (error) {
                //console.log(error);
            } else {
                //console.log(result);
                for (var i in result.data.data) {
                    departmentList.push(result.data.data[i].department);

                }
                setTimeout(() => {
                    setOpen(false)
                }, 500);
            }
        });

    }

    const setStartDateHanle = (e) => {
        const momentStartDate = moment(e.target.value, 'YYYY-MM-DD');
        const formattedStartDate = momentStartDate.format('DD-MM-YYYY');
        setStratDate(formattedStartDate);
    }

    const setEndDateHanle = (e) => {
        const momentStartDate = moment(e.target.value, 'YYYY-MM-DD');
        const formattedEndDate = momentStartDate.format('DD-MM-YYYY');
        setEndDate(formattedEndDate)
    }



    const getAllTransaction = async () => {
        var sampleData = [];
        let id = null;
        let name = Cookies.get('arka');
        if (name) {
            let t = JSON.parse(name)
            setCreated_by(t.id);
            id = t.id;
        }
        setTransactionList(sampleData)
        getAllTransactionByCreatedBy(id, startDate, endDate, likeValue, (error, resultt) => {
            if (error) {
                //console.log(error);
            } else {
                if (resultt.data.data == "notAvailable") {
                    //console.log(resultt.data.data)
                    setIsTrApiHaveData('no')
                } else {
                    //console.log(resultt);
                    setTransactionList(resultt.data.data);
                    setIsTrApiHaveData('yes')
                }

            }
        })
    }

    const generatePDF = () => {
        const doc = new jsPDF({ orientation: 'landscape' });
        var str = "Page";
        // Set the font and font size for the footer
        doc.setFont("helvetica", "italic");
        doc.setFontSize(10);

        // Get the total number of pages
        var totalPages = doc.internal.getNumberOfPages();

        // Loop through each page and add the footer
        for (var i = 1; i <= totalPages; i++) {
            // Go to the current page
            doc.setPage(i);

            // Set the position of the footer text
            var footerX = 10;
            var footerY = doc.internal.pageSize.getHeight() - 10;

            // Add the footer text
            doc.text("Arka Fincap Limited (AFL) is a customer-focused, digitally enabled, systemically important Non-Banking Finance Company (NBFC).", footerX, footerY);
        }

        // Save or display the PDF document

        // Add table
        // doc.text('Table Example', 10, 10);
        doc.addImage(layout_set_logo, 'JPEG', 10, 10)
        // doc.text('Arka Fincap Limited AFL', 30, 30);
        doc.autoTable({
            styles: { halign: 'center' },
            headStyles: { fillColor: ['#06444a'] },
            tableLineColor: ['#06444a'],
            tableLineWidth: [0.1],
            startY: 35,
            html: '#table-to-pdf', // ID of your table element
        });
        // Save the PDF

        doc.save('document.pdf');
    };

    useEffect(() => {
        setOpen(true);
        calluserList();
        callGetDepartment();
        // getAllTransaction();
        callGetemp();
    }, [])



    return (
        <>
            <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '20px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}>
                    <div>
                        <CircularProgress color="inherit" />
                        <p>Loading...</p>
                    </div>
                </Backdrop>


                <Container className='border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a' }}>
                    <Row style={{ padding: 5, marginBottom: '0px', backgroundColor: '#06444a', marginRight: 0, marginLeft: 0 }}>
                        <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <p className='h5 HEADING' style={{ margin: 0, color: 'white', fontSize: 'medium' }}>COMPLIANCE</p>
                        </Col>
                    </Row>
                </Container>
                <Container style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginBottom: '5px' }}>
                    {/* <Row style={{ padding: 5, marginBottom: '30px', backgroundColor: 'white', marginRight: 0, marginLeft: 0 }}>
                        <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <Button style={{ fontSize: 'small', backgroundColor: '#06444a', alignSelf: 'end' }} onClick={() => navigate("/createCompliance", { state: { demo, userList, departmentList } })}>+ Create New</Button>{' '}
                        </Col>
                    </Row> */}
                    <Row style={{ padding: 5, marginBottom: '0px', backgroundColor: 'white', marginRight: 0, marginLeft: 0 }}>
                        <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <Form.Group controlId="formGridDepartment">
                                {/* <Form.Label>Select Department</Form.Label> */}
                                <Form.Select placeholder='Select User'
                                    style={{ fontSize: 'small', marginRight: 5, marginLeft: 5 }}
                                    value={userLike}
                                    onChange={(e) => setUserLike(e.target.value)}
                                >
                                    {userList.map(usr => (
                                        <option>{usr}</option>
                                    ))}
                                    <option>All User</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group controlId="formGridDepartment">
                                {/* <Form.Label>Select Department</Form.Label> */}
                                <Form.Select placeholder='Select Department'
                                    style={{ fontSize: 'small', marginRight: 0, marginLeft: 10 }}
                                    value={departmentLike}
                                    onChange={(e) => setDepartmentLike(e.target.value)}
                                >
                                    {departmentList.map(usr => (
                                        <option>{usr}</option>
                                    ))}
                                    <option>All Department</option>
                                </Form.Select>
                            </Form.Group>
                            <Button onClick={() => callGetCompliance()} style={{ marginRight: 0, marginLeft: 20, fontSize: 'small', backgroundColor: '#06444a' }} >Pull</Button>
                            <Button style={{ fontSize: 'small', backgroundColor: '#06444a', alignSelf: 'end', marginLeft: 20 }} onClick={() => navigate("/createCompliance", { state: { demo, userList, departmentList } })}>+ Create New</Button>{' '}

                            {/* <p className='h5' style={{ margin: 0, color: 'black' }}>Transaction report</p> */}
                            {/* <Button style={{ marginRight: 10, marginLeft: 10, fontSize: 'small', backgroundColor: '#06444a' }} onClick={() => getAllTransaction()}>Pull</Button> */}
                            {/* <Button style={{ fontSize: 'small', backgroundColor: '#06444a' }} onClick={generatePDF}>Generate PDF</Button> */}
                        </Col>

                    </Row>
                </Container>
                {/* <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a' }}> */}


                <div style={{ paddingBottom: '10px', padding: 7 }}>
                    {isApiHaveData == 'yes' ?
                        comList.map((number, i) => {
                            return (
                                <Row className='border shadow-sm' style={{ padding: 5, paddingLeft: 0, paddingRight: 0, margin: 0, marginBottom: '0px', backgroundColor: 'white', backgroundColor: 'yellopw', borderRadius: '6px', marginBottom: '5px', marginTop: '5px' }} onClick={() => navigate("/viewCompliance", { state: { Id: number.id, demo, userList, departmentList } })}>
                                    <Col style={{ textAlign: 'start' }}>
                                        <p className='COMPLIANCENAME' style={{ marginBottom: 0 }}>{number.compliance_name}</p>
                                        <p className='COMPLIANCEUSEREMAIL' style={{ marginBottom: 0, color: '#0504aa' }}>{number.user_email}</p>
                                    </Col>
                                    <Col md="auto"  className='COMPLIANCETYPE' > 
                                      <p style={{marginBottom:'0px'}}>{number.compliance_type}</p>
                                      <p style={{fontSize:'xx-small',marginBottom:'0px',color:'green'}}>{number.start_date}  To {number.end_date}</p>
                                    </Col>
                                </Row>
                            )
                        }) : <Row style={{ backgroundColor: 'white', padding: '0px', paddingLeft: 0, paddingRight: 0, margin: 0 }}>
                            <p>Empty List</p>
                        </Row>
                    }
                </div>
            </Container>


            <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '50px' }}>
                <Row style={{ padding: 5, marginBottom: '0px', backgroundColor: '#06444a', marginRight: 0, marginLeft: 0 }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <p className='h5 HEADING' style={{ margin: 0, color: 'white', fontSize: 'medium' }}>TRANSACTION REPORT</p>
                    </Col>
                </Row>
            </Container>
            <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a' }}>
                <Row style={{ padding: 5, marginBottom: '30px', backgroundColor: 'white', marginRight: 0, marginLeft: 0 }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <label for="birthday" style={{ marginRight: 5 }}>From</label>
                        <Form.Group controlId="formGridcomplianceName">
                            <Form.Control type="date" placeholder={startDate}
                                style={{ fontSize: 'small' }}
                                required
                                defaultValue={startDate} onChange={(e) => setStartDateHanle(e)} />
                        </Form.Group>
                        {/* <input type="date" placeholder='fddd' id="birthday" name="birthday" defaultValue={startDate} onChange={(e) => setStartDateHanle(e)} /> */}
                        <label for="birthday" style={{ marginRight: 5, marginLeft: 5 }}>To</label>
                        {/* <input type="date" style={{ marginRight: 5 }} id="birthday" name="birthday" defaultValue={endDate} onChange={(e) => setEndDateHanle(e)} /> */}
                        <Form.Group controlId="formGridcomplianceName">
                            <Form.Control type="date" placeholder={endDate}
                                style={{ fontSize: 'small' }}
                                required
                                defaultValue={endDate} onChange={(e) => setEndDateHanle(e)} />
                        </Form.Group>
                        <Form.Group controlId="formGridDepartment">
                            {/* <Form.Label>Select Department</Form.Label> */}
                            <Form.Select placeholder='Select Department'
                                style={{ fontSize: 'small', marginRight: 5, marginLeft: 5 }}
                                value={likeValue}
                                onChange={(e) => setLikeValue(e.target.value)}
                            >
                                {departmentList.map(usr => (
                                    <option>{usr}</option>
                                ))}
                                <option>nofilter</option>
                            </Form.Select>
                        </Form.Group>
                        {/* <p className='h5' style={{ margin: 0, color: 'black' }}>Transaction report</p> */}
                        <Button style={{ marginRight: 10, marginLeft: 20, fontSize: 'small', backgroundColor: '#06444a' }} onClick={() => getAllTransaction()}>Pull</Button>
                        <Button style={{ fontSize: 'small', backgroundColor: '#06444a',marginLeft: 10 }} onClick={generatePDF}>Generate PDF</Button>
                    </Col>

                </Row>

                <Container style={{ 'overflow-x': 'auto', marginBottom: '5px' }}>
                    <Table bordered hover id="table-to-pdf">
                        <thead>
                            <tr >
                                <th className='TABLEHEADER' scope="col">S.N</th>
                                <th className='TABLEHEADER' scope="col">User</th>
                                <th className='TABLEHEADER' scope="col">Compliance Name</th>
                                <th className='TABLEHEADER' scope="col">Department</th>
                                <th className='TABLEHEADER' scope="col">Org Doc</th>
                                <th className='TABLEHEADER' scope="col">User uploaded Doc</th>
                                <th className='TABLEHEADER' scope="col">Admin uploaded doc</th>
                                <th className='TABLEHEADER' scope="col">Start Date</th>
                                <th className='TABLEHEADER' scope="col">End Date</th>
                                <th className='TABLEHEADER' scope="col">Comment</th>
                                <th className='TABLEHEADER' scope="col">Status</th>
                                <th className='TABLEHEADER' scope="col">Submitted on</th>
                                <th className='TABLEHEADER' scope="col">Is Deleted</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isTrApiHaveData == 'yes' ?
                                transactionList.map((number, i) => {
                                    return (
                                        <tr>
                                            <td className='TABLECOLUMN'>{i + 1}</td>
                                            <td className='TABLECOLUMN'>{number.user_email}</td>
                                            <td className='TABLECOLUMN'>{number.comName}</td>
                                            <td className='TABLECOLUMN'>{number.department}</td>
                                            {/* <td><Card.Img variant="top" src={fileData} height={50} width={100} /></td> */}
                                            <td className='TABLECOLUMN'><a download={number.orgDocFile}>{number.orgDocFile}</a></td>
                                            <td className='TABLECOLUMN'>{number.uploadedDocFile}</td>
                                            <td className='TABLECOLUMN'>{number.adminUploadedDocFile}</td>
                                            <td className='TABLECOLUMN'>{moment(number.startDate).format("DD/MM/YYYY")}</td>
                                            <td className='TABLECOLUMN'>{moment(number.endDate).format("DD/MM/YYYY")}</td>
                                            <td className='TABLECOLUMN'>{number.comment}</td>
                                            <td className='TABLECOLUMN' style={{ color: number.status == 'not submitted' ? 'gray' : number.status == 'submitted' ? 'green' : 'orange' }}>{number.status == 'not submitted' ? 'Not Submitted' : number.status == 'submitted' ? 'Submitted' : number.status}</td>
                                            <td className='TABLECOLUMN'>{moment(number.submitted_on).format("lll") == 'Invalid date' ? '...' : moment(number.submitted_on).format("lll")}</td>
                                            <td className='TABLECOLUMN'>{number.isDeleted}</td>
                                         </tr>
                                    )
                                }) : <p style={{marginTop:'10px'}}>Empty List</p>
                            }
                        </tbody>
                    </Table>
                </Container>
                {/* <Footer /> */}
            </Container>
        </>


    )
}

const mapStateToProps = (state) => {
    console.log(state);
    return { todos: state.todoReducer }
}

const mapDispatchToProps = (dispatch) => ({
    addTodo: (todo) => { dispatch(addTodo(todo)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome)