import { useState } from 'react';

function Loader() {
    const [refreshing, setRefreshing] = useState(1);

    return (
        <>
            <div style={{ width:'100%',height: '100%', position: 'relative', top: 1, display: 'flex', textAlign: 'center', justifyContent: 'center', backgroundColor: 'white' }}>
                <div style={{ alignSelf: 'center' }}>
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loader;
