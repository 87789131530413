import React, { useEffect } from 'react'
import { Button, Container, Col, Row, Table, Card, Form, Modal } from 'react-bootstrap'
import { useState } from 'react';
import { addTodo } from '../redux/actions/todo';
import { setUser } from '../redux/actions/user';
import { connect } from 'react-redux';
import { GoogleLogin, Google } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { verifyEmail } from '../util/api-call';
import Loader from '../components/Loader';
import './LoginStyle.css';


function Login({ addTodo, setUser, users }) {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [todo, setTodo] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    let data = {
        token: null,
        userName: null,
        profile: null,
        id: null
    }


    const navigate = useNavigate();


    const verify = async (email) => {
        let v = await verifyEmail(email)
        // console.log(v.data.data)
        if (v.data.token) {
            var decoded = jwtDecode(v.data.token);

            data.token = v.data.token;
            data.id = decoded.id;
            const myJSON = JSON.stringify(data);
            Cookies.set('arka', myJSON, { expires: 1 });

            if (decoded.user_type === 'admin') {
                setIsRefreshing(false);
                addTodo('admin');
            } else {
                setIsRefreshing(false);
                addTodo('user');
            }
        } else {
            // console.log(v.data.data)

            setIsRefreshing(false);
            setShowAlert(true)
        }

    }


    const responseMessage = (response) => {
        setIsRefreshing(true)
        var decoded = jwtDecode(response.credential);
        console.log(decoded)
        data.userName = decoded.name;
        data.profile = decoded.picture;
        verify(decoded.email);
        setUser(decoded.name)
    };


    const errorMessage = (error) => {
       // console.log(error);
    };


    return (
        <>
            {
                showAlert ? <div class="alert alert-danger" role="alert">
                    Check your email !
                </div> : null
            }
            <Container className='h-100  shadow-lg border' style={{height: '100px', padding: 0, borderRadius: 0, paddingTop: 0, marginTop: '20px' }}>
                <Row style={{ padding: 0, marginBottom: '30px', backgroundColor: '#06444a', marginRight: 0, marginLeft: 0 }}>
                    <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <p className='LOGINHEADING' style={{ margin: 0, color: 'white' }}>Login</p>
                    </Col>
                </Row>
                <Container className='h-100 mb-3' style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <Card className='h-100'>
                        <Card.Body>
                            <Card.Title className='mb-3 LOGINCARDHEADING'>Login With Google</Card.Title>
                            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                        </Card.Body>
                    </Card>
                </Container>
                {isRefreshing ? <Loader /> : null}
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    addTodo: (todo) => { dispatch(addTodo(todo)) },
    setUser: (user) => { dispatch(setUser(user)) }
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)