import React, { useEffect, useState } from 'react'
import { Button, Container, Col, Row, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getComplianceByUserEmail } from '../util/api-call';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './AdminHomeStyle.css';
import { AiOutlineRight } from "react-icons/ai";
import { VscChevronRight } from "react-icons/vsc";




function UserHome() {
    const navigate = useNavigate();
    const [comList, setComList] = useState([]);
    const [comId, setComId] = useState('');
    const [comName, setComName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [docFile, setDocFile] = useState('');
    const [isComment, setIsComment] = useState('');
    const [isApiHaveData, SetIsApiHaveData] = useState('no');



    const user = [
        { "id": 0, "name": "IT compliance", "class": "abhijeet.tiwari@arka.com" },
        { "id": 1, "name": "operation compliance", "class": "mayur@arka.com" },
        { "id": 2, "name": "SME compliance", "class": "kausar@arkafincap" },
        { "id": 3, "name": "Digital compliance", "class": "nikita@arka.com" },

    ]

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const getLoginUserInfo = () => {
        setOpen(true)

        let name = Cookies.get('arka');
        let t = JSON.parse(name)
        var decoded = jwtDecode(t.token);
        // console.log(t);
        setUserId(decoded.id);
        setUserEmail(decoded.user_email);
        console.log(userEmail);
        console.log(userId);
        setTimeout(() => {
            setOpen(false)
        }, 500);


    }


    const callComplianceList = async () => {
        // let userEmail = 'mayur.chudasma@arkafincap.com'
        setOpen(true)

        await getComplianceByUserEmail(userEmail, (error, result) => {
            if (error) {
                console.log(error);
            }
            else if (result.data.data == 'notAvailable') {
                console.log(result.data.data)

                SetIsApiHaveData('no');
                setTimeout(() => {
                    setOpen(false)
                }, 500);
            } else {
                SetIsApiHaveData('yes')
                console.log(result.data.data)
                setComList(result.data.data);
                setTimeout(() => {
                    setOpen(false)
                }, 500);
            }
        })
    }

    useEffect(() => {
        setOpen(true);
        callComplianceList()
        getLoginUserInfo();
    }, [userEmail])
    return (
        <>
            {/* <Container className='shadow-lg border' style={{ padding: 10, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '20px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Row style={{ padding: 5, marginBottom: '30px', backgroundColor: '#06444a', marginRight: -9, marginLeft: -9 }}>
                    <Col xs={12} md={8} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <p className='h5' style={{ margin: 0, color: 'white' }}>Compliance</p>
                    </Col>
                </Row>

                <div>
                    {isApiHaveData == 'yes' ?
                        comList.map((number, i) => {
                            return (

                                <Row className='border shadow' style={{ padding: 5, margin: 0, marginBottom: '5px' }} onClick={() => navigate("/userViewCompliance", { state: { comId: number.id, comName: number.compliance_name, userId: userId, docFile: number.doc_file_name, startDate: number.start_date, endDate: number.end_date, isComment: number.is_comment } })}>
                                    <Col style={{ textAlign: 'start' }}>
                                        <p className='h5' style={{ marginBottom: 0 }}>{number.compliance_name}</p>
                                        {number.created_at ? <p style={{ marginBottom: 0, fontSize: 12, color: 'orange' }}>{moment(number.created_at).format('LL')}</p> : null}
                                    </Col>
                                    <Col md="auto" className=''> <p>{number.compliance_type}</p></Col>
                                   
                                </Row>

                            )
                        }) : 'Empty List'
                    }
                </div>
            </Container> */}

            <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a', marginTop: '20px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}>
                    <div>
                        <CircularProgress color="inherit" />
                        <p>Loading...</p>
                    </div>
                </Backdrop>


                <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a' }}>
                    <Row style={{ padding: 5, marginBottom: '0px', backgroundColor: '#06444a', marginRight: 0, marginLeft: 0 }}>
                        <Col xs={12} md={12} style={{ borderRadius: 10, textAlign: 'start', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <p className='h5 HEADING' style={{ margin: 0, color: 'white', fontSize: 'medium' }}>COMPLIANCE</p>
                        </Col>
                    </Row>
                </Container>
                {/* <Container className='shadow-lg border' style={{ padding: 0, borderRadius: 0, paddingTop: 0, borderColor: '#06444a' }}> */}
                <div style={{ paddingBottom: '10px', padding: 7 }}>
                    {isApiHaveData == 'yes' ?
                        comList.map((number, i) => {
                            return (
                                <Row className='MAINCONTAINER shadow-sm' style={{ margin: 0, marginBottom: '0px', backgroundColor: 'yellow', backgroundColor: 'yellopw', borderRadius: '6px', marginBottom: '5px', marginTop: '5px', border: '0.2px solid black', borderBottom: '2px solid green', }} onClick={() => navigate("/userViewCompliance", { state: { comId: number.id, comName: number.compliance_name, userId: userId, docFile: number.doc_file_name, startDate: number.start_date, endDate: number.end_date, isComment: number.is_comment } })}>
                                    <Col style={{ textAlign: 'start' }}>
                                        <p className='COMPLIANCENAME' style={{ marginBottom: 0 }}>{number.compliance_name}</p>
                                        <p className='COMPLIANCEUSEREMAIL' style={{ marginBottom: 0, color: '#0504aa' }}>{moment(number.created_at).format('LL')}</p>
                                    </Col>
                                    <Col md="auto" className='USERCOMPLIANCETYPE'>
                                        <Col>
                                            <p style={{ marginBottom: '0px' }}>{number.compliance_type}</p>
                                            <p style={{ fontSize: 'xx-small', marginBottom: '0px', color: 'green' }}>{number.start_date}  To {number.end_date}</p>
                                        </Col>
                                        <Col style={{display:'flex',alignItems:'center',marginLeft:'10px'}}>
                                          
                                            <p style={{fontSize:'30px',marginBottom:'0px',display:'flex'}}><VscChevronRight/></p>
                                        </Col>
                                    </Col>

                                </Row>

                            )
                        }) : <p style={{marginBottom: 10,marginTop: 10}}>Empty List</p>
                    }
                </div>
            </Container>
        </>

    )
}

const mapStateToProps = (state) => {
    console.log(state);
    return { todos: state.todoReducer }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UserHome)