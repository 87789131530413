import React from 'react'
import { Card } from 'react-bootstrap'

function Footer() {
    return (
        <div style={{height:'10px', position:'absolute', bottom:-300,width:'100%'}}>
    <Card style={{padding:10,borderRadius:0}}>
      <Card.Body>© 2023 ARKA FINCAP LIMITED</Card.Body>
    </Card>
        </div>
    )
}

export default Footer