import logo from './logo.svg';
import './App.css';
import AdminHome from './screens/AdminHome';
import { Button, Container } from 'react-bootstrap';
import Header from './components/Header';
import CreateCompliance from './screens/CreateCompliance';
import Footer from './components/Footer';
import ViewCompliance from './screens/ViewCompliance';
import UserHome from './screens/UserHome';
import UserViewCompliance from './screens/UserViewCompliance';
import { BrowserRouter, Route, Routes, json, Navigate  } from 'react-router-dom';
import Protected from './screens/Protected';
import { connect } from 'react-redux';
import Login from './screens/Login';
import ErrorPage from './screens/ErrorPage';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { addTodo } from './redux/actions/todo';
import jwtDecode from 'jwt-decode';
import { setUser } from './redux/actions/user';
import EditCompliance from './screens/EditCompliance';



function App({ addTodo, todos, setUser, users }) {

  useEffect(() => {
    let name = Cookies.get('arka');

    if (name) {
      //console.log(name);
      let t = JSON.parse(name)
      var decoded = jwtDecode(t.token);
      //console.log(t)
      setUser(t.userName);

      if (decoded.user_type === 'admin') {
        addTodo('admin');
      } else {
        addTodo('user');
      }
    }

  }, [''])

  return (

    <div>
      <Header />
      <BrowserRouter>
        <Routes>
          {todos === 'admin' ? (
            <>
              <Route path='/' element={<AdminHome />} />
              <Route path='/createCompliance' element={<CreateCompliance />} />
              <Route path='/viewCompliance' element={<ViewCompliance />} />
              <Route path='/viewCompliance/Edit' element={<EditCompliance />} />
            </>
          ) : todos === 'user' ? (
            <>
              <Route path='/' element={<UserHome />} />
              <Route path='/userViewCompliance' element={<UserViewCompliance />} />
            </>
          ) : (
            // If 'todos' is not 'admin' or 'user', redirect to Login
            <>
              <Route path='/' element={<Login />} />
              <Route path='*' element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
      </BrowserRouter>

      {/* <AdminHome/> */}
      {/* <CreateCompliance/> */}
      {/* <ViewCompliance/> */}
      {/* <UserHome/> */}
      {/* <Footer/> */}
      {/* <UserViewCompliance/> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return { todos: state.todoReducer }
}

const mapDispatchToProps = (dispatch) => ({
  addTodo: (todo) => { dispatch(addTodo(todo)) },
  setUser: (user) => { dispatch(setUser(user)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
