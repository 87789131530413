import { ADD_TODO, UPDATE_TODO } from "../actions/action-types"
// const [todo, setTodo] = useState({
//     title: '',
//     description: ''
// })

let initialState = 'login'

export default (state = initialState, action) => {

    switch (action.type) {
        case ADD_TODO:
            //add todo
            //console.log("adding todo");
            return action.payload
        case UPDATE_TODO:
            const updateState = state.map(todo => {
                if (todo.id == action.payload.todoId) {
                    todo.title = action.payload.todo.title
                    todo.description = action.payload.todo.description
                }
                return todo
            })
            return updateState
        default:
            return state
    }

}