import { SET_USER } from "../actions/action-types";


let initialState = 'system'

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_USER:
            //add todo
            //console.log("adding user");
            return action.payload
        default:
            return state
    }

}