import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import animation_lny7vn3p from '../img/animation_lny7vn3p.json'; // Import your animation JSON

const DeleteLoader = () => {
  useEffect(() => {
    const container = document.getElementById('lottie-container'); // Create a container for the animation

    const animation = lottie.loadAnimation({
      container,
      renderer: 'svg', // You can choose 'svg', 'canvas', or 'html'
      loop: true, // Set to true for looping animation
      autoplay: true, // Set to true to start the animation immediately
      animationData: animation_lny7vn3p, // Import the animation JSON
    });

    // Cleanup when the component unmounts
    return () => {
      animation.destroy();
    };
  }, []);

  return <div id="lottie-container" style={{ width: '150px', height: '150px' }}></div>;
};

export default DeleteLoader;
