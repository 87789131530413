import { ADD_TODO , UPDATE_TODO} from "./action-types"

export const addTodo= (todo)=>({
  type:ADD_TODO,
  payload:todo
})

// export const setUser=(name)=>({
//     type:SET_USER,
//     payload:name
// }) 


export const updateTodo=(todo, id)=>({
    type:UPDATE_TODO,
    payload:{todo:todo,todoId:id}
})